<template lang="pug">
//- This component is used at order progress

//- TODO: Child points should be nested inside the `.point` elements
//- TODO: The height should automatically adjust its growth instead of using absolute positioning
//- TODO: Position default can be removed
.vc-k-step-progress-indicator-bar(:class="containerClass")
  .bar(:style="`--bar-width: ${barWidth}%`")
  .points
    .point.start-point

    template(v-for="(step, index) in steps")
      .point.value-point(
        :style="`--point-position: ${step.position}%;`"
        :class="{ 'passed-point': index < value, 'current-point': index === value, 'unreached-point': index > value }"
      )
        .label-wrapper
          .label(v-if="step.label") {{ step.label }}
          slot(
            name="label"
            :step="step.step"
          )

      //- for child points
      .child-points-wrapper(
        v-if="step.substeps.length > 0"
        :style="childPointsStyleFor(step, index)"
        :class="{ 'passed-point': index < value, 'current-point': index === value, 'unreached-point': index > value }"
      )
        .child-bar(
          :style="`--child-bar-width: ${step.substeps[step.substeps.length - 1].position}%`"
        )
        .child-point(
          v-for="(substep, subindex) in step.substeps"
          :key="subindex"
          :style="`--child-point-position: ${substep.position}%`"
        )
          .label-wrapper
            .label(v-if="substep.label") {{ substep.label }}
            slot(
              name="sublabel"
              :step="step.step"
              :substep="substep.step"
            )
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'
const COLOR_TYPE_MAP = {
  'is-ci': 'is-ci',
  'is-black': 'is-black'
}

const props = defineProps({
  value: { type: [Number, String], required: true },
  steps: { type: Array, required: true },
  options: {
    type: Object,
    default: () => {
      return {
        colorType: null,
        isPassedStepVisible: false,
        isUnreachedStepVisible: false
      }
    }
  }
})

const emit = defineEmits(['input'])

const convertedOptions = computed(() => {
  return {
    isPassedStepVisible: props.options.isPassedStepVisible || false,
    isUnreachedStepVisible: props.options.isUnreachedStepVisible || false,
    colorType: props.options.colorType || 'is-ci'
  }
})

const containerClass = computed(() => {
  return [
    convertedOptions.value.isPassedStepVisible ? 'passed-step-visible' : null,
    convertedOptions.value.isUnreachedStepVisible
      ? 'unreached-step-visible'
      : null,
    COLOR_TYPE_MAP[convertedOptions.value.colorType]
  ]
    .filter(Boolean)
    .join(' ')
})

const barWidth = computed(() => {
  const selectedStep = props.steps.find((step, index) => {
    return props.value === index
  })

  return selectedStep.position
})

function childPointsStyleFor(point, index) {
  const nextPoint = props.steps[index + 1]

  if (!nextPoint) return ''

  return [
    `--child-points-position: ${point.position}%;`,
    nextPoint
      ? `--child-points-width: ${nextPoint.position - point.position}%`
      : null
  ]
    .filter(Boolean)
    .join(' ')
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/size.sass'

.vc-k-step-progress-indicator-bar
  --point-width: 20px
  --point-border-width: 3px
  --child-point-width: 10px
  --child-point-border-width: 1px

  position: relative
  width: 100%
  height: 4rem

  &.is-ci
    --bar-color: #{color.$ci}
    --point-color: #{color.$ci}
  &.is-black
    --bar-color: #{color.$black}
    --point-color: #{color.$black}

  > .bar
    position: relative
    z-index: 1
    top: calc(var(--point-width) * 0.5 + var(--point-border-width))
    width: var(--bar-width)
    height: 5px
    background-color: var(--bar-color)
    transition: all 1s ease-out
  > .points
    position: relative
    width: calc(100% - var(--point-width))
    left: calc(var(--point-width) * 0.5)
    > .point
      top: 0
      position: absolute
      z-index: 3
      width: var(--point-width)
      height: var(--point-width)
      border-radius: var(--point-width)
      transform: translate(-50%, 0)
      &.passed-point, &.unreached-point
        visibility: hidden
      &.current-point
        visibility: visible
      &.start-point
        left: 0
        background-color: white
        border: var(--point-border-width) solid color.$dark
      &.value-point
        left: var(--point-position)
        background-color: var(--point-color)
        box-shadow: 0 0 5px rgba(10, 10, 10, 0.5)
        border: var(--point-border-width) solid color.$white
      > .label-wrapper
        position: relative
        top: 1.5rem
        display: flex
        justify-content: center

    > .child-points-wrapper
      position: absolute
      z-index: 2
      left: var(--child-points-position)
      width: var(--child-points-width)
      &.passed-point, &.unreached-point
        visibility: hidden
      &.current-point
        visibility: visible

      > .child-bar
        position: absolute
        left: 0
        top: calc(var(--point-width) * 0.5)
        transform: translate(0, -50%)
        width: var(--child-bar-width)
        height: 3px
        color: var(--bar-color)
        background-color: var(--bar-color)

      > .child-point
        position: absolute
        background: color.$ci
        left: var(--child-point-position)
        top: calc(var(--point-width) * 0.5)
        transform: translate(0, -50%)
        width: var(--child-point-width)
        height: var(--child-point-width)
        border-radius: var(--child-point-width)

        > .label-wrapper
          position: relative
          top: 1.5rem
          display: flex
          justify-content: center
          .label
            font-size: font.$size-7

  &.passed-step-visible .point.passed-point
    visibility: visible

  &.unreached-step-visible .point.unreached-point
    visibility: visible
</style>
