<template lang="pug">
.k-step-progress(
  :is="component"
  :value="value"
  :steps="convertedSteps"
  :options="options"
)
  template(
    v-if="$scopedSlots.label"
    #label="props"
  )
    slot(
      name="label"
      :step="props.step"
    )
  template(
    v-if="$scopedSlots.sublabel"
    #sublabel="props"
  )
    slot(
      name="sublabel"
      :step="props.step"
      :substep="props.substep"
    )
</template>

<script setup>
import { ref, computed } from 'vue'
import IndicatorBar from './k_step_progress/indicator-bar.vue'

const STYLE_TYPE_MAP = {
  'indicator-bar': IndicatorBar
}

const props = defineProps({
  styleType: { type: String },
  value: { type: [Number, String], required: true },
  steps: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((step) => {
        if (step.position == undefined) return false
        if (!(step.position >= 0 && step.position <= 100)) return false

        return true
      })
    }
  },
  options: { type: Object }
})

const emit = defineEmits(['input'])

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})

const convertedSteps = computed(() => {
  return props.steps.map((step, index) => {
    return {
      label: step.label,
      position: step.position,
      step: step.step || index,
      substeps: (step.substeps || []).map((substep, subindex) => {
        return {
          label: substep.label,
          position: substep.position,
          step: substep.step || subindex
        }
      })
    }
  })
})
</script>
