<template lang="pug">
k-step-progress(
  style-type="indicator-bar"
  :value="value"
  :steps="steps"
  :options="{ colorType: props.orderShipment.state === 'canceled' ? 'is-black' : 'is-ci' }"
)
  template(#label="props")
    label.label(v-if="props.step === stateFor('ready')")
      span.tag.is-warning {{ aasmStateLocaleText('order/shipment', 'state', 'ready') }}
    label.label(v-if="props.step === stateFor('tallying')")
      span.tag.is-info {{ aasmStateLocaleText('order/shipment', 'state', 'tallying') }}
    label.label(v-if="props.step === stateFor('shipped')")
      span.tag.is-success {{ aasmStateLocaleText('order/shipment', 'state', 'shipped') }}
    label.label(v-if="props.step === stateFor('canceled')")
      span.tag.is-danger {{ aasmStateLocaleText('order/shipment', 'state', 'canceled') }}
  template(#sublabel="props")
    template(v-if="props.step === stateFor('tallying')")
      span.tag.is-info.is-light
        | {{ transitionStateFor(orderShipment.transition_state_ids[props.substep]).name }}
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import KStepProgress from '@sharedComponents/common/k-step-progress.vue'

const STATE_MAP = {
  ready: 0,
  tallying: 1,
  shipped: 2,
  canceled: 3
}

const props = defineProps({
  orderShipment: { type: Object }
})

const store = useStore()

const stateFor = (state) => {
  return STATE_MAP[state]
}

const value = computed(() => {
  return STATE_MAP[props.orderShipment.state]
})

const steps = computed(() => {
  return [
    { position: 33.33, step: stateFor('ready') },
    {
      position: 66.67,
      step: stateFor('tallying'),
      substeps: props.orderShipment.transition_state_ids.map((id, index) => {
        const position =
          Math.round(
            100 / (props.orderShipment.transition_state_ids.length + 1)
          ) *
          (index + 1)

        return {
          step: index,
          position: position
        }
      })
    },

    { position: 100, step: stateFor('shipped') },
    { position: 100, step: stateFor('canceled') }
  ]
})

function transitionStateFor(id) {
  return store.getters['orderShipmentTransitionStates/find'](id)
}
</script>
